<template>
  <footer class="footer min-w-1400">
    <div class="footer-content">
      <!-- <p>Supervisado por la Comisión Nacional Bancaria y la Comisión Reguladora de Valores</p> -->
      <p>Copyright © INTERMEDIARIA Y PROMOTORA ECONÓMICA, S.A. DE C.V., SOFOM, E.N.R.</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
   margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #136D58 ;
  color: white;
  min-width: 1400px;
  max-width: 1620px;
  height: 100px;
}

.footer-content {
  font-size: 17px;
  text-align: center;
  margin: 0 auto;
}
</style>
